export const de_DE_base = {
    'profileDetails_days_monday': 'Montag',
    'profileDetails_days_tuesday': 'Dienstag',
    'profileDetails_days_wednesday': 'Mittwoch',
    'profileDetails_days_thursday': 'Donnerstag',
    'profileDetails_days_friday': 'Freitag',
    'profileDetails_days_saturday': 'Samstag',
    'profileDetails_days_sunday': 'Sonntag',

    'profileDetails_opening_hours': 'Öffnungszeiten',

    'error': 'Ein Fehler ist aufgetreten. Ein Administrator wurde informiert.',

    'checkpoint_title': 'Prüfort für die Hauptuntersuchung',

    'booking_no_slots': 'Aktuell sind in dieser Werkstatt keine Termine für den gewählten Zeitraum verfügbar.',
    'booking_blocking': 'Termin wird reserviert...',
    'booking_submit': 'Termin sichern',
    'booking_next_free_slot': 'Nächster freier Termin:',
    'booking_error_nearest_slot_gone': 'Puh, die Werkstatt hat einiges zu tun: Der {day} ist jetzt schon ausgebucht. Ab dem {newDay} sind wieder Termine frei.',

    'garage_in': 'Die beste Autowerkstatt in {location}',
    'garages_in': 'Die {amount} besten Autowerkstätten in {location}',
    'garage_in_bqc': '{amount} Battery Quick Check Anbieter in {location}',
    'garages_in_bqc': '{amount} Battery Quick Check Anbieter in {location}',
    'garage_in_mmc': '{amount} Mehrmarken Center in {location}',
    'garages_in_mmc': '{amount} Mehrmarken Center in {location}',
    'garages_in_breadcrumb': 'Autowerkstätten in {location}',
    'inrcrease_search_radius': 'Suchradius erweitern',
    'adjust_filters': 'Filter ändern',
    'call_us': 'Uns anrufen: +49 89 244 182 010',
    'adjust_criteria': 'Suchkriterien anpassen',
    'free_garage': 'Freie Werkstatt',
    'next_free_appointment': 'Nächster freier Termin',
    'phone_numer': 'Telefonnummer:',
    'authorized_garage': 'Vertragswerkstatt',
    'cost_estimate': 'Kostenvoranschlag',
    'close_quickview': 'Schnellansicht schliessen',
    'open_quickview': 'Schnellansicht Werkstatt',
    'readmore': 'weiterlesen',
    'main_examination': 'HU Prüfung',
    'sorting': 'Sortierung',
    'shortest_distance': 'Geringste Entfernung',
    'most_affordable': 'Günstigster Preis',
    'earliest_appointment': 'Frühester Termin',
    'best_rating': 'Beste Bewertung',
    'back_to_result': 'Zurück zum Ergebnis',
    'show_results': 'Ergebnisse anzeigen',
    'reset_filters': 'Filter zurücksetzen',
    'search': 'Suchen',
    'apply': 'Übernehmen',
    'cancel': 'Abbrechen',
    'garage_nearby': 'Werkstatt in der Nähe',
    'appointment_window': 'Terminzeitraum',
    'comfort_services': 'Komfortservices',
    'filter': 'Filter',
    'ratings': 'Bewertungen',
    'show_more_ratings': 'Mehr Bewertungen anzeigen',
    'next_appointment': 'Nächster Termin',
    'appointment_selection': 'Zur Terminauswahl',
    'book_appointment': 'Termin buchen',
    'garage_offerings': 'Das bietet die Werkstatt an',
    'services': 'Services',
    'recommendation': 'Weiterempfehlung',
    'costevaluation_garage': 'Kostenvoranschlag bei {name} anfordern und Termin buchen',
    'costevaluation_now': 'Kostenvoranschlag und Termin buchen',
    'repareo_customer': 'repareo Kunde',
    'in_x_days': '(in {days} Tagen)',
    'tomorrow': '(morgen)',
    'today': '(heute)',
    'not_specified': 'Keine Angabe',
    'listHeaderRepareo' : '',
    'listHeaderMMC' : '',
    'listHeaderBQC' : '',
    'booking_headline': 'Buchung abschließen',
    'booking_contact_headline': 'Deine Kontaktdaten',
    'booking_label_firstName': 'Vorname*',
    'booking_label_lastName': 'Nachname*',
    'booking_label_mail': 'E-Mail*',
    'booking_label_phone': 'Telefon*',
    'booking_label_street': 'Straße und Hausnummer*',
    'booking_label_strretAdditional': 'Adresszusatz (optional)',
    'booking_label_postalCode': 'Postleitzahl*',
    'booking_label_city': 'Wohnort*',
    'booking_label_numberplate': 'Kennzeichen*',
    'booking_label_mileage': 'Killometerstand (optional)',
    'booking_label_message': 'Nachricht an die Werkstatt',
    'booking_contact_addressHead': 'Hol- und Bring Adresse',
    'booking_contact_addressLeadText': 'Wo soll das Auto abgeholt und wieder hingebracht werden?',
    'booking_label_mileageHint': 'Eine grobe Schätzung genügt',
    'booking_checkboxes_head': 'Buchung abschließen',
    'booking_checkboxes_marketing': 'Ich möchte Neuigkeiten, Angebote und Aktionen von repareo via Email erhalten. Widerruf jederzeit möglich.' ,
    'booking_form_submit': 'Termin buchen',
    'booking_additional_head': 'Termindetails',
    'booking_additional_subhead': 'Ein paar letzte Angaben für die Werkstatt.',
    'booking_overview_service': 'Service',
    'booking_overview_appointment': 'Termin',
    'booking_overview_vehicle': 'Fahrzeug',
    'booking_overview_adjust': 'Bearbeiten',
    'received': 'eingegangen',
    'booking_accepted': 'Buchung bei',
    'confirmed': 'bestätigt.',
    'yourbookingat': 'Dein Termin wird in Kürze von',
    'freeCancellation': 'Kostenfreie Stornierung',
    'directPassOn': 'Übertragung aller Infos an die Werkstatt',
    'validationErrors_firstName': 'Bitte gebe deinen Vornamen an.',
    'validationErrors_lastName': 'Bitte gebe deinen Nachnamen an.',
    'validationErrors_email': 'Bitte gebe eine gültige E-Mail-Adresse an.',
    'validationErrors_phone': 'Bitte gebe eine gültige Telefonnummer an.',
    'validationErrors_numberPlate': 'Bitte gebe das Kfz-Kennzeichen deines Fahrzeuges an.',
    'validationErrors_mileage': 'Bitte gebe den Kilometerstand deines Fahrzeuges an.',
    'validationErrors_message': 'Bitte gebe dein Anliegen an.',
    'validationErrors_tos': 'Bitte akzeptiere unsere Nutzungsbedingungen und Datenschutzerklärung.',
    'requiredHint': '* Mit "*" gekennzeichnete Felder sind Pflichtfelder.',
}
